<div class="mt-5 d-flex justify-content-center" *ngIf="loading">
  <div>
    <img class="mb-4 logo logo-themed" alt="SensInfo" />
    <p class="text-center">
      <i
        class="bwi bwi-spinner bwi-spin bwi-2x text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="sr-only">{{ "loading" | i18n }}</span>
    </p>
  </div>
</div>
