<div
  class="tw-mx-auto tw-mt-5 tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-p-8"
>
  <div>
    <img class="logo logo-themed" alt="SensInfo" />
    <h3 bitTypography="h3" class="tw-my-8 tw-mb-3 tw-text-center">
      {{ "readingPasskeyLoading" | i18n }}
    </h3>

    <div
      class="tw-rounded-md tw-border tw-border-solid tw-border-secondary-300 tw-bg-background tw-p-6"
    >
      <div class="tw-flex tw-flex-col tw-items-center">
        <ng-container *ngIf="currentState === 'assert'">
          <bit-icon [icon]="Icons.CreatePasskeyIcon" class="tw-my-10"></bit-icon>
          <p bitTypography="body1">{{ "readingPasskeyLoadingInfo" | i18n }}</p>
          <button
            type="button"
            bitButton
            block
            [loading]="true"
            buttonType="primary"
            class="tw-mb-4"
          >
            {{ "loading" | i18n }}
          </button>
        </ng-container>

        <ng-container *ngIf="currentState === 'assertFailed'">
          <bit-icon [icon]="Icons.CreatePasskeyFailedIcon" class="tw-my-10"></bit-icon>
          <p bitTypography="body1">{{ "readingPasskeyLoadingInfo" | i18n }}</p>
          <button
            type="button"
            bitButton
            block
            buttonType="primary"
            class="tw-mb-4"
            (click)="retry()"
          >
            {{ "tryAgain" | i18n }}
          </button>
        </ng-container>
      </div>
      <p bitTypography="body1" class="tw-mb-0">
        {{ "troubleLoggingIn" | i18n }}<br />
        <a routerLink="/login">{{ "useADifferentLogInMethod" | i18n }}</a>
      </p>
    </div>
  </div>
</div>
