<h1 class="tw-text-4xl !tw-text-alt2">Begin Teams Starter Free Trial Now</h1>
<div class="tw-pt-32">
  <h2 class="tw-text-2xl">
    Millions of individuals, teams, and organizations worldwide trust SensInfo for secure password
    storage and sharing.
  </h2>
</div>
<ul class="tw-mt-12 tw-flex tw-flex-col tw-gap-10 tw-text-2xl tw-text-main">
  <li>
    Powerful security for up to 10 users
    <div class="tw-mt-2 tw-text-base">
      Have more than 10 users?
      <a routerLink="/register" [queryParams]="{ org: 'teams', layout: 'teams1' }"
        >Start a Teams trial</a
      >
    </div>
  </li>
  <li>Collaborate and share securely</li>
  <li>Deploy and manage quickly and easily</li>
  <li>Access anywhere on any device</li>
  <li>Create your account to get started</li>
</ul>
<div class="tw-mt-28 tw-flex tw-flex-col tw-items-center tw-gap-5">
  <app-logo-forbes></app-logo-forbes>
  <app-logo-us-news></app-logo-us-news>
</div>
