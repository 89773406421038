<main
  class="tw-flex tw-min-h-screen tw-w-full tw-mx-auto tw-flex-col tw-gap-9 tw-bg-background-alt tw-px-4 tw-pb-4 tw-pt-14 tw-text-main"
>
  <div class="tw-text-center">
    <div class="tw-px-8">
      <div *ngIf="icon" class="tw-mb-8">
        <bit-icon [icon]="icon"></bit-icon>
      </div>
      <bit-icon [icon]="logo" class="tw-mx-auto tw-block tw-max-w-72 sm:tw-max-w-xs"></bit-icon>
    </div>
    <h1 *ngIf="title" bitTypography="h3" class="tw-mt-8 sm:tw-text-2xl">
      {{ title }}
    </h1>
    <p *ngIf="subtitle" bitTypography="body1">{{ subtitle }}</p>
  </div>
  <div class="tw-mb-auto tw-max-w-md tw-mx-auto tw-flex tw-flex-col tw-items-center">
    <div
      class="tw-rounded-xl tw-mb-9 tw-mx-auto sm:tw-bg-background sm:tw-border sm:tw-border-solid sm:tw-border-secondary-300 sm:tw-p-8"
    >
      <ng-content></ng-content>
    </div>
    <ng-content select="[slot=secondary]"></ng-content>
  </div>
  <footer class="tw-text-center">
    <div *ngIf="showReadonlyHostname">{{ "accessing" | i18n }} {{ hostname }}</div>
    <ng-container *ngIf="!showReadonlyHostname">
      <ng-content select="[slot=environment-selector]"></ng-content>
    </ng-container>
    <ng-container *ngIf="showYearAndVersion">
      <div>&copy; {{ year }} SensInfo</div>
      <div>{{ version }}</div>
    </ng-container>
  </footer>
</main>
