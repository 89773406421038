<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  SensInfo Web<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
    A modified version of the SensInfo&reg; Web Vault for SensInfo (an unofficial rewrite of the
    SensInfo&reg; server).<br />
    SensInfo is not associated with the SensInfo&reg; project nor SensInfo
  </div>
</div>
