<div class="tw-mt-auto">
  <!-- [attr.icon] is used to keep the icon attribute on the bit-nav-item after prod mode is enabled. Matches other navigation items and assists in automated testing. -->
  <bit-nav-item
    *ngFor="let product of accessibleProducts$ | async"
    [icon]="product.icon"
    [text]="product.name"
    [route]="product.appRoute"
    [attr.icon]="product.icon"
    [forceActiveStyles]="product.isActive"
  >
  </bit-nav-item>
  <ng-container *ngIf="moreProducts$ | async as moreProducts">
    <section
      *ngIf="false"
      class="tw-mt-2 tw-flex tw-w-full tw-flex-col tw-gap-2 tw-border-0 tw-border-t tw-border-solid tw-border-t-text-alt2"
    >
      <span class="tw-text-xs !tw-text-alt2 tw-p-2 tw-pb-0">{{ "moreFromSensInfo" | i18n }}</span>
      <a
        *ngFor="let more of moreProducts"
        [href]="more.marketingRoute"
        target="_blank"
        rel="noreferrer"
        class="tw-flex tw-py-2 tw-px-4 tw-font-semibold !tw-text-alt2 !tw-no-underline hover:tw-bg-primary-300/60 [&>:not(.bwi)]:hover:tw-underline"
      >
        <i class="bwi bwi-fw {{ more.icon }} tw-mt-1 tw-mx-1"></i>
        <div>
          {{ more.otherProductOverrides?.name ?? more.name }}
          <div *ngIf="more.otherProductOverrides?.supportingText" class="tw-text-xs tw-font-normal">
            {{ more.otherProductOverrides.supportingText }}
          </div>
        </div>
      </a>
    </section>
  </ng-container>
</div>
