<h1 class="tw-text-3xl !tw-text-alt2">Start your 7-day Enterprise free trial</h1>
<div class="tw-pt-20">
  <h2 class="tw-text-2xl">
    SensInfo is the most trusted password manager designed for seamless administration and employee
    usability.
  </h2>
</div>
<ul class="tw-mt-12 tw-flex tw-flex-col tw-gap-10 tw-text-2xl tw-text-main tw-list-none tw-pl-0">
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Instantly and securely share credentials with the groups and individuals who need them</span
    >
  </li>
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Strengthen company-wide security through centralized administrative control and
      policies</span
    >
  </li>
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Streamline user onboarding and automate account provisioning with flexible SSO and SCIM
      integrations</span
    >
  </li>
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Migrate to SensInfo in minutes with comprehensive import options</span
    >
  </li>
  <li class="tw-flex tw-items-center">
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4 tw-flex-none"></i
    ><span class="tw-flex-auto"
      >Give all Enterprise users the gift of 360º security with a free Families plan</span
    >
  </li>
</ul>
<div class="tw-mt-28 tw-flex tw-flex-col tw-items-center tw-gap-5">
  <app-logo-badges></app-logo-badges>
</div>
